<template>
  <b-container fluid>
    <bo-page-title />

    <b-card v-if="isList" no-body>
      <b-card-header>
        <b-row class="justify-content-end mb-4">
          <b-col md=2 class="text-right">
            <b-button class="m-l-15 btn-rounded" v-if="moduleRole('add', 'BoBookingTransport')" variant="success" :to="{ name: 'BoBookingTransport', params: {pageSlug: 'add'} }">
              <i class="fa fa-plus-circle mr-2" />
              Add Booking
            </b-button>
          </b-col>
        </b-row>
        <b-row align-h="end">
          <b-col md>
            <b-row class="gutter-1">
              <b-col md="6" lg="4" xl="3">
                <v-select @input="doFilter()" v-model="filter.vehicle" :options="mrVehicle" :reduce="data => String(data.value)" />
              </b-col>
              <b-col md="6" lg="4" xl="3">
                <v-select @input="doFilter()" v-model="filter.status" :options="mrStatus" :reduce="data => data.value" />
              </b-col>
              <b-col md="6" lg="4" xl="3"></b-col>
            </b-row>
          </b-col>
          <b-col md="auto">
            <SearchInput :value.sync="filter.search" @search="doFilter" />
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
         <b-table
          :fields="tableFields"
          :items="dataList || []"
          :primary-key="idKey"
          :busy="!dataList"
          responsive
          striped
          show-empty
        >
          <template #empty>
            <div class="text-center">
              <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
              <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
            </div>
          </template>
          <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-3 text-info"></b-spinner>
            <strong class="text-info">Loading...</strong>
          </div>
        </template>
          <template #cell(num)="v">
            {{(data.per_page*(data.current_page-1))+v.index+1}}
          </template>
          <template #cell(tbt_person)="data">
            <div class="font-weight-semibold text-dark">{{ data.value }}</div>
            <small>
              <template v-if="data.item.tbt_date">
                Booked At: {{ data.item.tbt_date | moment('DD MMM YYYY, HH:mm') }}
              </template>
            </small>
          </template>
          <template #cell(tbt_vehicle)="data">
            <div>{{ data.item.vehicle }}</div>
          </template>
          <template #cell(tbt_start_time)="data">
            {{ timeOfUse(data.item.tbt_start_time, data.item.tbt_end_time) }}
          </template>
          <template #cell(tbt_status)="data">
            <b-badge v-if="data.value == 'pending'" variant="info">Pending</b-badge>
            <b-badge v-else-if="data.value == 'approve'" variant="success">Approved</b-badge>
            <b-badge v-else-if="data.value == 'reject'" variant="danger">Reject</b-badge>
          </template>
          <template #cell(action)="data">
            <router-link v-if="moduleRole('edit')" class="btn btn-icon btn-outline-info" v-b-tooltip.hover="'View Details'" :to="{name: $route.name, params: {pageSlug: 'detail', pageId: data.item.tbt_book_code } }"><i class="fas fa-eye"></i></router-link>
            <template v-if="data.item.tbt_status == 'pending'">
              <router-link v-if="moduleRole('approve')" class="btn btn-icon btn-outline-success" variant="outline-success" v-b-tooltip.hover="'Give Approval'" :to="{ name: $route.name, params: { pageSlug: 'approve', pageId: data.item.tbt_book_code } }"><i class="fas fa-check"></i></router-link>
              <b-button v-if="moduleRole('reject')" @click="doReject(data.item)" class="btn-icon" variant="outline-danger" v-b-tooltip.hover="'Reject'"><i class="fas fa-times"></i></b-button>
            </template>
          </template>
        </b-table>
      </b-card-body>
      <b-card-footer>
      <template v-if="(dataList||[]).length&&data.total>10">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <label for="perPage" class="mb-0 mr-2">Show</label>
              <b-form-select
                size="sm"
                id="perPage"
                class="w-auto mx-50"
                v-model="perPage"
                :options="Config.dataShownOptions"
              />
            <label for="perPage" class="mb-0 ml-2">items per page</label>
          </div>
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </div>
      </template>
    </b-card-footer>
    </b-card>
    <b-card no-body v-else-if="$route.params.pageSlug == 'approve'">
      <Form :row.sync="row" :dropdown="dropdown" :mrCashless="mrCashless" />
    </b-card>
    <b-card no-body v-else-if="$route.params.pageSlug == 'detail'">
      <Detail :row.sync="row" />
    </b-card>
    <b-modal 
        id="rejectModal"
        title="Reject Booking Schedule"
        hide-footer
        :no-enforce-focus="true"
        centered
      >
        <ValidationObserver ref="VFormReject" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(submitReject)">
            <b-form-group label-for="reasonField">
              <template #label>Reason <strong class="text-danger">*</strong></template>
              <b-form-textarea id="reasonField"
                v-model="dataModal.reason"
                placeholder="cth: bentrok dengan jadwal lain" rows="3" />
              <VValidate name="Reason" v-model="dataModal.reason" rules="required" />
            </b-form-group>
            <b-row class="justify-content-end">
              <b-col md='auto'>
                <b-button type="button" variant="transparent" @click="doCloseModalReject">Cancel</b-button>
                <b-button type="submit" variant="success btn-rounded">Submit</b-button>
              </b-col>
            </b-row> 
          </b-form>
        </ValidationObserver>
    </b-modal>
  </b-container>
</template>
<script>
	import GlobalVue from '@/libs/Global.vue'
  import Form from './Form.vue'
  import Detail from './Detail.vue'
  
  export default {
		extends: GlobalVue,
    components: {
      Form, Detail
    },
    data() {
      return {
        idKey: 'tbt_id',
        statusKey: 'tbt_status',
        mrValidation: {},
        tableFields: [
          { key: 'num', label: '#' },
          { key: 'tbt_book_code', label: 'Booking Code' },
          { key: 'tbt_person', label: 'Name' },
          { key: 'tbt_vehicle', label: 'Vehicle' },
          { key: 'tbt_start_time', label: 'Time of Use' },
          { key: 'tbt_status', label: 'Status' },
          { key: 'action', label: 'Action' },
        ],
        mrVehicle: [],
        mrStatus: [],
        dropdown: {},
        mrCashless: [],
        dataModal: {}
      }
    },
    mounted(){
      this.setFilter()
      this.apiGet()
    },
    methods: {
      setFilter(){
        this.filter.status = this.$route.query.status || 'pending'
        this.filter.vehicle = this.$route.query.vehicle || 'all'
        this.filter.search = this.$route.query.search || ''
      },
      doCloseModalReject(){
        this.$bvModal.hide('rejectModal')
      },
      doReject(row){
        this.$set(this, 'dataModal', row)
        this.$bvModal.show('rejectModal')
      },
      submitReject(){
        this.$refs.VFormReject.validate().then(success => {
          if(!success) return
          this.$swal({
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })
          return this.doSubmit('/do/' + this.modulePage, Object.assign({ type: 'reject'}, {...this.dataModal}), (type, resp) => {
            if(type == 'success'){
              this.$swal({
              title: resp.message,
              icon: 'success',
              confirmButtonText: 'Oke'
            }).then(result => {
              if(result.value){
                this.$bvModal.hide('rejectModal')
                this.$set(this, 'dataModal', {})
                this.apiGet()
              }
            })
            }
          }, "POST", "VFormReject")
        })
      }
    },
    watch:{
      $route(){
        this.setFilter()
        this.apiGet()
      },
    }
  }
</script>