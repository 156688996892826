<template>
    <b-card no-body>
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">Detail {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg=4>
            <b-form-group>
              <label>Generate Booking on</label>
              <p>
                <template v-if="row.tbt_date">
                  {{ row.tbt_date | moment('LLL') }}
                </template>
                </p>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4">
            <b-form-group>
              <label>Name</label>
              <p>{{ row.tbt_person }}</p>
            </b-form-group>
          </b-col>
          <b-col lg=4>
            <b-form-group>
              <label>Vehicle</label>
                <p>{{ row.vehicle }}</p>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md=4>
            <b-form-group>
              <label>Status</label>
              <div>
                <b-badge v-if="row.tbt_status == 'pending'" variant="info">Pending</b-badge>
                <b-badge v-else-if="row.tbt_status == 'approve'" variant="success">Approved</b-badge>
                <b-badge v-else-if="row.tbt_status == 'reject'" variant="danger">Reject</b-badge>
              </div>
            </b-form-group>
          </b-col>
          <b-col md=4 v-if="row.tbt_status == 'reject'">
            <label>Reject Reason</label>
            <p>{{ row.tbt_reject_reason }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md=12>
            <div class="panel">
              <div class="panel_title">Usage Plan</div>
              <div class="panel_body">
                <b-form-row>
                  <b-col lg=3>
                    <b-form-group>
                      <label>Estimate Start Time</label>
                      <p v-if="row.tbt_start_time">{{ row.tbt_start_time | moment('lll') }}</p>
                    </b-form-group>
                  </b-col>
                  <b-col lg=3>
                    <b-form-group>
                      <label>Estimate Finish Time</label>
                      <p v-if="row.tbt_end_time">{{ row.tbt_end_time | moment('lll') }}</p>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col md=2>
                    <p>Destinations</p>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col md=12 v-for="(data, index) in row.tbt_destination" :key="index">
                    <b-row>
                      <b-col lg="3">
                        <b-form-group>
                          <label>PIC</label>
                          <p>{{ data.pic }}</p>
                        </b-form-group>
                      </b-col>
                      <b-col lg="3">
                        <b-form-group>
                          <label>Address</label>
                          <p>{{ data.destination }}</p>
                        </b-form-group>
                      </b-col>
                      <b-col lg="3">
                        <b-form-group>
                          <label>Purpose</label>
                          <p>{{ data.purpose }}</p>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-form-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <router-link :to="{name: $route.name}" class="btn btn-rounded btn-default mr-3">Back</router-link>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
</template>
<script>
 export default {
    props: {
      row: Object,
    },
    methods: {
      beforeStart(date){
        return new Date(date).setHours(23, 59, 59) <= new Date(this.row.tbt_start_time).setHours(0, 0, 0)
      },
      removeDestination(data){
        this.row.tbt_destination = this.row.tbt_destination.filter(v => !v.id || v.id != data.id)
      },
      beforeTime(date){
        return date <= new Date(this.row.tbt_start_time)
      },
    }
 }
</script>
