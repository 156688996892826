<template>
  <b-card no-body>
    <b-form>
        <form-wizard ref="formWizard" class="custom-wizard" shape="circle" step-size="sm" title="Vehicle Checking" color="#289f48">
          <template #title>
            <b-card-header>
              <h4 class="card-title">Schedule Confirmation</h4>
            </b-card-header>
          </template>
          <tab-content :before-change="validateStepOne" title="Vehicle Checklist" class="card-body" icon="fas fa-tasks">
            <validation-observer v-slot="{ handleSubmit }" ref="VFormOne">
              <b-form @submit.prevent="handleSubmit(validateStepOne)">
                <b-card class="border" header-bg-variant="primary">
                  <template #header>
                    <h5 class="card-title text-white">Vehicle Condition Checking</h5>
                  </template>
                  <b-row>
                    <b-col md="6" lg="4">
                      <b-form-group label-for="useDeviasi">
                        <b-form-checkbox
                          id="useDeviasi"
                          v-model="row.tt_use_deviasi"
                          value="Y"
                          unchecked-value="N"
                        >
                          Use Deviasi
                        </b-form-checkbox>
                      </b-form-group>
                      <b-form-group label-for="checkSpeedo">
                        <template #label>Speedometer - Ready <strong class="text-danger">*</strong></template>
                        <b-input-group append="KM">
                          <b-form-input type="number" v-model="row.tt_ready_speedometer" id="checkSpeedo" placeholder="e.g. 29727" />
                        </b-input-group>
                        <VValidate name="Speedometer - Inital" v-model="row.tt_ready_speedometer" :rules="{required: true, regex: /^\d*\.?\d*$/}" />
                      </b-form-group>
                      <b-row class="gutter-1">
                        <b-col cols="6">
                          <b-form-group label-for="checkFuelInit">
                            <template #label>Fuel - Initial <strong class="text-danger">*</strong></template>
                            <v-select :searchable="false" id="checkFuelInit" v-model="row.tt_init_fuel" placeholder="e.g. 50%" :options="dropdownPercentage" :reduce="data => data.value" />
                            <VValidate name="Fuel - Initial" v-model="row.tt_init_fuel" rules="required" />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group label-for="checkFuelReady">
                            <template #label>Fuel - Ready <strong class="text-danger">*</strong></template>
                            <v-select :searchable="false" id="checkFuelReady" :reduce="data => data.value" label="label" v-model="row.tt_ready_fuel" placeholder="e.g. 50%" :options="dropdownPercentage" />
                            <VValidate name="Fuel - Ready" v-model="row.tt_ready_fuel" rules="required" />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-card class="border" body-class="p-3">
                        <h5 class="card-title">Engine Check</h5>
                        <b-row class="gutter-2">
                          <b-col md="4" lg="6">
                            <b-form-group class="mb-0" label="Braking System" label-for="engineCheck">
                              <v-select :searchable="false" v-model="row.ready_braking_system" placeholder="Braking System" :options="dropdownDefault" :reduce="data => data.value" />
                              <VValidate name="Braking System" v-model="row.ready_braking_system" :rules="mustOK" />
                            </b-form-group>
                          </b-col>
                          <b-col md="4" lg="6">
                            <b-form-group class="mb-0" label="Engine Oil" label-for="engineCheck">
                              <v-select :searchable="false" v-model="row.ready_engine_oil" placeholder="Engine Oil" :options="dropdownDefault" :reduce="data => data.value" />
                              <VValidate name="Engine Oil" v-model="row.ready_engine_oil" :rules="mustOK" />
                            </b-form-group>
                          </b-col>
                          <b-col md="4" lg="6">
                            <b-form-group class="mb-0" label="Accu Battery" label-for="engineCheck">
                              <v-select :searchable="false" v-model="row.ready_accu_battery" placeholder="Accu Battery" :options="dropdownDefault" :reduce="data => data.value" />
                              <VValidate name="Accu Battery" v-model="row.ready_accu_battery" :rules="mustOK" />
                            </b-form-group>
                          </b-col>
                          <b-col md="4" lg="6" v-if="row.tt_vehicle_type == 'car'">
                            <b-form-group class="mb-0" label="AC" label-for="engineCheck">
                              <v-select :searchable="false" v-model="row.ready_ac" placeholder="AC" :options="dropdownDefault" :reduce="data => data.value" />
                              <VValidate name="AC" v-model="row.ready_ac" :rules="mustOK" />
                            </b-form-group>
                          </b-col>
                          <b-col md="4" lg="6" v-if="row.tt_vehicle_type == 'car'">
                            <b-form-group class="mb-0" label="Wiper Liquid" label-for="engineCheck">
                              <v-select :searchable="false" v-model="row.ready_wiper_liquid" placeholder="Wiper Liquid" :options="dropdownDefault" :reduce="data => data.value" />
                              <VValidate name="Wiper Liquid" v-model="row.ready_wiper_liquid" :rules="mustOK" />
                            </b-form-group>
                          </b-col>
                          <b-col md="4" lg="6">
                            <b-form-group class="mb-0" label="Repair Tools" label-for="engineCheck">
                              <v-select :searchable="false" v-model="row.ready_repair_tools" placeholder="Repair Tools" :options="dropdownDefault" :reduce="data => data.value" />
                              <VValidate name="Repair Tools" v-model="row.ready_repair_tools" :rules="mustOK" />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                    <b-col md="6" lg="8">
                      <b-card class="border" body-class="p-3">
                        <h5 class="card-title">Tires</h5>
                        <b-row class="gutter-3">
                          <b-col md="6">
                            <label>Initial</label>
                            <b-row v-if="row.tt_vehicle_type == 'motorcycle'" class="gutter-1" align-h="center">
                              <b-col cols="6">
                                <v-select :searchable="false" v-b-tooltip.hover.noninteractive="'Front'" placeholder="Front" :options="dropdownDefault" v-model="row.init_tires_front" :reduce="data => data.value" />
                                <VValidate name="Front - Intial" v-model="row.init_tires_front" :rules="required" />
                              </b-col>
                              <b-col cols="6">
                                <v-select :searchable="false" v-b-tooltip.hover.noninteractive="'Rear'" placeholder="Rear" :options="dropdownDefault" v-model="row.init_tires_rear" :reduce="data => data.value" />
                                <VValidate name="Rear - Intial" v-model="row.init_tires_rear" :rules="required" />
                              </b-col>
                            </b-row>
                            <b-row v-else class="gutter-1" align-h="center">
                              <b-col cols="6">
                                <v-select :searchable="false" v-b-tooltip.hover.noninteractive="'Front Left'" placeholder="Front Left" :options="dropdownDefault" v-model="row.init_tires_fl" :reduce="data => data.value" />
                                <VValidate name="Front Left - Intial" v-model="row.init_tires_fl" :rules="required" />
                              </b-col>
                              <b-col cols="6">
                                <v-select :searchable="false" v-b-tooltip.hover.noninteractive="'Front Right'" placeholder="Front Right" :options="dropdownDefault" v-model="row.init_tires_fr" :reduce="data => data.value" />
                                <VValidate name="Front Right - Intial" v-model="row.init_tires_fr" :rules="required" />
                              </b-col>
                              <b-col cols="6">
                                <v-select :searchable="false" v-b-tooltip.hover.noninteractive="'Rear Left'" placeholder="Rear Left" :options="dropdownDefault" v-model="row.init_tires_rl" :reduce="data => data.value" />
                                <VValidate name="Rear Left - Intial" v-model="row.init_tires_rl" :rules="required" />
                              </b-col>
                              <b-col cols="6">
                                <v-select :searchable="false" v-b-tooltip.hover.noninteractive="'Rear Right'" placeholder="Rear Right" :options="dropdownDefault" v-model="row.init_tires_rr" :reduce="data => data.value" />
                                <VValidate name="Rear Right - Intial" v-model="row.init_tires_rr" :rules="required" />
                              </b-col>
                              <b-col cols="6">
                                <v-select :searchable="false" v-b-tooltip.hover.noninteractive="'Spare Wheel'" placeholder="Spare Wheel" :options="dropdownDefault" v-model="row.init_spare_wheel" :reduce="data => data.value" />
                                <VValidate name="Spare Wheel - Intial" v-model="row.init_spare_wheel" :rules="required" />
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col md="6">
                            <label>Tires - Ready</label>
                            <b-row v-if="row.tt_vehicle_type == 'motorcycle'" class="gutter-1" align-h="center">
                              <b-col cols="6">
                                <v-select :searchable="false" v-b-tooltip.hover.noninteractive="'Front'" placeholder="Front" :options="dropdownDefault" v-model="row.ready_tires_front" :reduce="data => data.value" />
                                <VValidate name="Front - Ready" v-model="row.ready_tires_front" :rules="mustOK" />
                              </b-col>
                              <b-col cols="6">
                                <v-select :searchable="false" v-b-tooltip.hover.noninteractive="'Rear'" placeholder="Rear" :options="dropdownDefault" v-model="row.ready_tires_rear" :reduce="data => data.value" />
                                <VValidate name="Rear - Ready" v-model="row.ready_tires_rear" :rules="mustOK" />
                              </b-col>
                            </b-row>
                            <b-row v-else class="gutter-1" align-h="center">
                              <b-col cols="6">
                                <v-select :searchable="false" v-b-tooltip.hover.noninteractive="'Front Left'" placeholder="Front Left" :options="dropdownDefault" v-model="row.ready_tires_fl" :reduce="data => data.value" />
                                <VValidate name="Front Left - Ready" v-model="row.ready_tires_fl" :rules="mustOK" />
                              </b-col>
                              <b-col cols="6">
                                <v-select :searchable="false" v-b-tooltip.hover.noninteractive="'Front Right'" placeholder="Front Right" :options="dropdownDefault" v-model="row.ready_tires_fr" :reduce="data => data.value" />
                                <VValidate name="Front Right - Ready" v-model="row.ready_tires_fr" :rules="mustOK" />
                              </b-col>
                              <b-col cols="6">
                                <v-select :searchable="false" v-b-tooltip.hover.noninteractive="'Rear Left'" placeholder="Rear Left" :options="dropdownDefault" v-model="row.ready_tires_rl" :reduce="data => data.value" />
                                <VValidate name="Rear Left - Ready" v-model="row.ready_tires_rl" :rules="mustOK" />
                              </b-col>
                              <b-col cols="6">
                                <v-select :searchable="false" v-b-tooltip.hover.noninteractive="'Rear Right'" placeholder="Rear Right" :options="dropdownDefault" v-model="row.ready_tires_rr" :reduce="data => data.value" />
                                <VValidate name="Rear Right - Ready" v-model="row.ready_tires_rr" :rules="mustOK" />
                              </b-col>
                              <b-col cols="6">
                                <v-select :searchable="false" v-b-tooltip.hover.noninteractive="'Spare Wheel'" placeholder="Spare Wheel" :options="dropdownDefault" v-model="row.ready_spare_wheel" :reduce="data => data.value" />
                                <VValidate name="Spare Wheel - Ready" v-model="row.ready_spare_wheel" :rules="mustOK" />
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-card>
                      <b-card class="border" body-class="p-3">
                        <h5 class="card-title">Cleanliness</h5>
                        <b-row class="gutter-2">
                          <b-col md="6" lg="4" v-if="row.tt_vehicle_type == 'car'">
                            <b-form-group class="mb-0" label="Interior" label-for="checkCleanliness">
                              <v-select :searchable="false" placeholder="Interior" v-model="row.ready_clean_interior" :options="dropdownDefault" :reduce="data => data.value" />
                              <VValidate name="Interior" v-model="row.ready_clean_interior" :rules="mustOK" />
                            </b-form-group>
                          </b-col>
                          <b-col md="6" lg="4">
                            <b-form-group class="mb-0" label="Exterior" label-for="checkCleanliness">
                              <v-select :searchable="false" placeholder="Exterior" :options="dropdownDefault" v-model="row.ready_clean_exterior" :reduce="data => data.value" />
                              <VValidate name="Exterior" v-model="row.ready_clean_exterior" :rules="mustOK" />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-card>
                <b-row>
                  <b-col md=4 lg=3>
                    <b-alert show :variant="row.tt_use_stnk == 'Y' ? 'success' : 'danger'">
                      <template v-if="row.tt_use_stnk == 'Y'">
                        <i class="fas fa-check"></i> 
                        <span> STNK Tersedia</span>
                      </template>
                      <template v-else>
                        <i class="fas fa-exclamation-triangle"></i> 
                        <span> STNK Tidak Tersedia</span>
                      </template>
                    </b-alert>
                    <b-form-group label-for="useSTNK">
                      <b-form-checkbox
                        id="useSTNK"
                        v-model="row.tt_use_stnk"
                        value="Y"
                      >
                        Use STNK
                      </b-form-checkbox>
                      <VValidate name="STNK" v-model="row.tt_use_stnk" :rules="required" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md=4 lg=3>
                    <b-form-group label-for="useCashless">
                      <b-form-checkbox
                        id="useCashless"
                        v-model="row.tt_use_cashless"
                        value="Y"
                        unchecked-value="N"
                      >
                        Use Cashless
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4" lg="3" v-if="row.tt_use_cashless == 'Y'" >
                    <b-form-group label-for="checkCashless">
                      <template #label>Cashless Card</template>
                      <v-select :searchable="false" @input="setCurrentAmount($event)" :options="mrCashless" v-model="row.tt_cashless_type" placeholder="Select Cashless Card" :reduce="data => data.value" />
                      <VValidate name="Cashless Card" v-model="row.tt_cashless_type" :rules="required" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3" v-if="row.tt_use_cashless == 'Y'" >
                    <b-form-group label-for="cashlessAmount" v-if="row.tt_use_cashless == 'Y'">
                      <template #label>Cashless Amount - Ready</template>
                      <b-input-group prepend="Rp">
                        <money id="cashlessAmount" v-model="row.tt_ready_cashless" v-bind="$parent.maskCurrency" placeholder="e.g. 100.000" class="form-control"></money>
                      </b-input-group>
                      <VValidate name="Cashless Amount - Ready" v-model="row.tt_ready_cashless" :rules="required" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label-for="checkChanges">
                      <template #label>Small Changes</template>
                      <b-input-group prepend="Rp">
                        <money id="checkChanges" v-model="row.tt_ready_small_change" v-bind="$parent.maskCurrency" placeholder="e.g. 100.000" class="form-control"></money>
                      </b-input-group>
                      <VValidate name="Small Changes" v-model="row.tt_ready_small_change" :rules="required" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label-for="checkChanges">
                      <template #label>Weight</template>
                      <v-select :reduce="data => data.value" v-model="row.tt_weight_payload" :options="dropdownWeight" placeholder="Select vehicle load weight" />
                      <VValidate name="Weight" v-model="row.tt_weight_payload" :rules="required" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group label-for="checkCashless">
                      <template #label>Working Tools</template>
                      <b-form-textarea v-model="row.tt_working_tools" placeholder="e.g. tripod, kamera" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label-for="checkCashless">
                      <template #label>Non-working Tools</template>
                      <b-form-textarea v-model="row.tt_non_working_tools" placeholder="e.g. tripod, kamera" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label-for="checkCashless">
                      <template #label>Notes</template>
                      <b-form-textarea v-model="row.tt_note" placeholder="e.g. pastikan alat tidak menggores interior kendaraan" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </tab-content>
          <tab-content :before-change="validateStepTwo" title="Routes" class="card-body" icon="fas fa-route">
            <validation-observer v-slot="{ handleSubmit }" ref="VFormTwo">
              <b-form @submit.prevent="handleSubmit(validateStepTwo)">
                <div class="info-block mb-4">
                  <h5>Time of Use</h5>
                  <span>{{ $parent.timeOfUse(row.tbt_start_time, row.tbt_end_time) }}</span>
                </div>
                <div class="info-block">
                  <h5>Routes</h5>
                  <b-table-lite
                    :fields="routeFieldsUpdate"
                    :items="row.tbt_destination"
                    striped
                    responsive
                    foot-row-variant="info"
                  >
                    <template #cell(num)="data">{{ data.index += 1 }}</template>
                    <template #cell(pic)="data">
                      <template v-if="data.item.onEdit">
                        <b-form-group>
                          <b-form-input v-model="row.tbt_destination[data.index].pic"  placeholder="e.g. 2.7" />
                        </b-form-group>
                      </template>
                      <template v-else>{{ data.value }}</template>
                      <VValidate keyName="PIC" :name="'PIC ' + (data.index + 1)" v-model="data.value" rules="required" />
                    </template>
                    <template #cell(destination)="data">
                      <template v-if="data.item.onEdit">
                        <b-form-group>
                          <b-form-textarea rows="2" v-model="row.tbt_destination[data.index].destination"  />
                        </b-form-group>
                      </template>
                      <template v-else>{{ data.value }}</template>
                      <VValidate keyName="Address" :name="'Address ' + (data.index + 1)" v-model="data.value" rules="required" />
                    </template>
                    <template #cell(distance)="data">
                      <b-form-group>
                        <b-input-group append="Km">
                          <b-form-input v-model="data.value" @input="updateDestination(data.value, data.index)" placeholder="e.g. 2.7" type="number" />
                        </b-input-group>
                        <VValidate keyName="Est Distance" :name="'Est Distance' + (data.index + 1)" v-model="data.value" :rules="{required: true, regex: /^\d*\.?\d*$/}" />
                      </b-form-group>
                    </template>
                    <template #cell(purpose)="data">
                      <template v-if="data.item.onEdit">
                        <b-form-group>
                          <b-form-textarea rows="2" v-model="row.tbt_destination[data.index].purpose"/>
                        </b-form-group>
                      </template>
                      <template v-else>{{ data.value }}</template>
                      <VValidate keyName="Purpose" :name="'Purpose ' + (data.index + 1)" v-model="data.value" rules="required" />
                    </template>
                    <template #cell(action)="data">
                      <template v-if="!data.item.onEdit">
                        <b-button class="btn-icon" @click="setUpdateRoute(data.item)" variant="outline-info" v-b-tooltip.hover="'Edit'">
                          <i class="fas fa-pencil-alt"></i>
                        </b-button>
                      </template>
                      <template v-else>
                        <b-button class="btn-icon" v-if="data.item.onEdit" @click="setUpdateRoute(data.item, false)" variant="outline-danger" v-b-tooltip.hover="'Cancel'">
                          <i class="fas fa-times"></i>
                        </b-button>
                        <b-button class="btn-icon" @click="deleteRoute(data.index)" variant="danger" v-b-tooltip.hover="'Delete'">
                          <i class="fas fa-trash"></i>
                        </b-button>
                      </template>
                    </template>
                    <template #custom-foot>
                      <b-tr>
                        <b-td colspan="3">
                          <div class="text-right">
                            Estimated Total Trip Distance:
                          </div>
                        </b-td>
                        <b-th colspan="2">{{totalEstimateDistance}}Km</b-th>
                        <b-td>
                          <b-button variant="outline-info" class="btn-rounded"
                          @click="row.tbt_destination.push({ id: row.tbt_destination.length + 1, onEdit: true, new: true, pic: '', destination: '', purpose: '' })">
                          <i class="fas fa-plus-circle mr-1"></i>
                          Add Destination
                        </b-button>
                        </b-td>
                      </b-tr>
                    </template>
                  </b-table-lite>
                </div>
              </b-form>
            </validation-observer>
          </tab-content>
          <tab-content title="Confirm Data" class="card-body" icon="fas fa-clipboard-check">
            <b-alert variant="info" show>
              <i class="fas fa-info-circle mr-2"></i>
              <span>Make sure the data shown below is correct.</span>
            </b-alert>
            <b-card no-body>
              <b-tabs card>
                <b-tab title="Schedule, Routes &amp; PIC">
                  <b-row class="gutter-3">
                    <b-col lg="10">
                      <b-row>
                        <b-col md="3">
                          <div class="info-block">
                            <h5>Vehicle Booked</h5>
                            <span>{{row.vehicle}}</span>
                          </div>
                        </b-col>
                        <b-col md="3">
                          <div class="info-block">
                            <h5>Booked by</h5>
                            <span>{{row.tbt_person}}</span>
                          </div>
                        </b-col>
                        <b-col md="3">
                          <div class="info-block">
                            <h5>Booked at</h5>
                            <span v-if="row.tbt_date">{{ row.tbt_date | moment('DD MMM YYYY, HH:mm') }}</span>
                          </div>
                        </b-col>
                        <b-col md="3">
                          <div class="info-block">
                            <h5>Time of Use</h5>
                            <span> {{ $parent.timeOfUse(row.tbt_start_time, row.tbt_end_time) }} </span>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="12">
                      <div class="info-block">
                        <h5>Routes</h5>
                        <b-table-lite
                          :fields="routeFields"
                          :items="row.tbt_destination"
                          striped
                          foot-row-variant="info"
                        >
                          <template #cell(num)="data">{{ data.index += 1 }}</template>
                          <template #cell(distance)="data">
                            {{ data.value }}Km
                          </template>
                          <template #custom-foot>
                            <b-tr>
                              <b-td colspan="3">
                                <div class="text-right">
                                  Estimated Total Trip Distance:
                                </div>
                              </b-td>
                              <b-th colspan="2">{{totalEstimateDistance}}Km</b-th>
                            </b-tr>
                          </template>
                        </b-table-lite>
                      </div>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Vehicle Condition &amp; Tools">
                  <b-card class="border" header-bg-variant="info">
                    <template #header>
                      <h5 class="card-title text-white">Vehicle Condition Checking</h5>
                    </template>
                    <b-row class="gutter-3">
                      <b-col md="3" lg="2">
                        <div class="info-block">
                          <h5>Speedometer - Ready</h5>
                          <span>{{row.tt_ready_speedometer}}</span>
                        </div>
                      </b-col>
                      <b-col md="3" lg="2">
                        <div class="info-block">
                          <h5>Fuel</h5>
                          <span class="d-block">Initial: {{row.tt_init_fuel}}%</span>
                          <span class="d-block">Ready: {{row.tt_ready_fuel}}%</span>
                        </div>
                      </b-col>
                      <b-col md="6" lg="8">
                        <div class="info-block">
                          <h5>Engine Check</h5>
                          <badge-check :value="row.ready_braking_system" trueValue="ok" label="Braking System" />
                          <badge-check :value="row.ready_engine_oil" trueValue="ok" label="Engine Oil" />
                          <badge-check :value="row.ready_accu_battery" trueValue="ok" label="Accu Battery" />
                          <badge-check v-if="row.tt_vehicle_type == 'car'" :value="row.ready_ac" trueValue="ok" label="Air Conditioner / AC" />
                          <badge-check v-if="row.tt_vehicle_type == 'car'" :value="row.ready_wiper_liquid" trueValue="ok" label="Wiper Liquid" />
                          <badge-check :value="row.ready_repair_tools" trueValue="ok" label="Repair Tools" />
                        </div>
                      </b-col>
                      <b-col md="10" lg="8">
                        <b-row>
                          <b-col md="6">
                            <div class="info-block">
                              <h5>Tires - Initial</h5>
                              <b-row v-if="row.tt_vehicle_type == 'motorcycle'" align-h="center" class="gutter-1">
                                <b-col cols="6">
                                  <badge-check :value="row.init_tires_front" trueValue="ok" label="Front" />
                                </b-col>
                                <b-col cols="6">
                                  <badge-check :value="row.init_tires_rear" trueValue="ok" label="Rear" />
                                </b-col>
                              </b-row>
                              <b-row v-else align-h="center" class="gutter-1">
                                <b-col cols="6">
                                  <badge-check :value="row.init_tires_fl" trueValue="ok" label="Front Left" />
                                </b-col>
                                <b-col cols="6">
                                  <badge-check :value="row.init_tires_fr" trueValue="ok" label="Front Right" />
                                </b-col>
                                <b-col cols="6">
                                  <badge-check :value="row.init_tires_rl" trueValue="ok" label="Rear Left" />
                                </b-col>
                                <b-col cols="6">
                                  <badge-check :value="row.init_tires_rr" trueValue="ok" label="Rear Right" />
                                </b-col>
                                <b-col cols="6">
                                  <badge-check :value="row.init_spare_wheel" trueValue="ok" label="Spare Wheel" />
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                          <b-col md="6">
                            <div class="info-block">
                              <h5>Tires - Ready</h5>
                              <b-row v-if="row.tt_vehicle_type == 'motorcycle'" align-h="center" class="gutter-1">
                                 <b-col cols="6">
                                  <badge-check :value="row.init_tires_front" trueValue="ok" label="Front" />
                                </b-col>
                                <b-col cols="6">
                                  <badge-check :value="row.init_tires_rear" trueValue="ok" label="Rear" />
                                </b-col>
                              </b-row>
                              <b-row v-else align-h="center" class="gutter-1">
                                 <b-col cols="6">
                                  <badge-check :value="row.init_tires_fl" trueValue="ok" label="Front Left" />
                                </b-col>
                                <b-col cols="6">
                                  <badge-check :value="row.init_tires_fr" trueValue="ok" label="Front Right" />
                                </b-col>
                                <b-col cols="6">
                                  <badge-check :value="row.init_tires_rl" trueValue="ok" label="Rear Left" />
                                </b-col>
                                <b-col cols="6">
                                  <badge-check :value="row.init_tires_rr" trueValue="ok" label="Rear Right" />
                                </b-col>
                                <b-col cols="6">
                                  <badge-check :value="row.init_spare_wheel" trueValue="ok" label="Spare Wheel" />
                                </b-col> 
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md="6" lg="4">
                        <div class="info-block">
                          <h5>Cleanliness</h5>
                          <badge-check v-if="row.tt_vehicle_type == 'car'" :value="row.ready_clean_interior" trueValue="ok" label="Interior" />
                          <badge-check :value="row.ready_clean_exterior" trueValue="ok" label="Exterior" />
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                  <b-row class="mb-4">
                    <b-col md="3">
                      <div class="info-block">
                        <h5>STNK</h5>
                          <template v-if="row.tt_use_stnk == 'Y'">
                            <i class="fas fa-check text-success"></i> 
                            <span class="text-success"> STNK Tersedia</span>
                          </template>
                          <template v-else>
                            <i class="fas fa-exclamation-triangle text-danger"></i> 
                            <span class="text-danger"> STNK Tidak Tersedia</span>
                          </template>
                      </div>
                    </b-col>
                    <b-col md="3">
                      <div class="info-block">
                        <h5>Cashless Card</h5>
                        <template v-if="row.tt_use_cashless == 'Y'">
                          <span class="d-block">{{getOptionLabel(mrCashless, row.tt_cashless_type)}}</span>
                          <small>Available Balance: <span class="font-weight-semibold">{{$parent.formatRupiah(row.tt_ready_cashless)}}</span></small>
                        </template>
                        <span class="d-block" v-else>Without Cashless</span>
                      </div>
                    </b-col>
                    <b-col md="3">
                      <div class="info-block">
                        <h5>Small Changes</h5>
                        <span>{{$parent.formatRupiah(row.tt_ready_small_change)}}</span>
                      </div>
                    </b-col>
                    <b-col md="3">
                      <div class="info-block">
                        <h5>Weight</h5>
                        <span>{{getOptionLabel(dropdownWeight, row.tt_weight_payload)}}</span>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="4">
                      <div class="info-block">
                        <h5>Working Tools</h5>
                        <span>{{row.tt_working_tools}}</span>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <div class="info-block">
                        <h5>Non-working Tools</h5>
                        <span>{{row.tt_non_working_tools}}</span>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <div class="info-block">
                        <h5>Notes</h5>
                        <span>{{row.tt_note || '-'}}</span>
                      </div>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-card>
          </tab-content>
          <template #footer="props">
            <b-card-footer>
              <div class="d-flex justify-content-between">
                <div>
                  <b-button class="btn-rounded" variant="outline-primary" v-if="props.activeTabIndex > 0" @click="props.prevTab()"><i class="fas fa-angle-left mr-2"></i> Previous</b-button>
                </div>
                <div>
                  <button v-if="props.activeTabIndex == 0" @click="$router.back()" type="button" class="btn btn-rounded btn-default mr-3">Back</button>
                  <b-button class="btn-rounded" variant="primary" v-if="props.activeTabIndex == 0" @click="props.nextTab()">Next <i class="fas fa-angle-right ml-2"></i></b-button>
                  <b-button class="btn-rounded" variant="primary" v-else-if="props.activeTabIndex == 1" @click="props.nextTab()">Next <i class="fas fa-angle-right ml-2"></i></b-button>
                  <b-button class="btn-rounded" variant="primary" v-else-if="props.activeTabIndex == 2" @click="doReadyToStart">Ready To Start <i class="fas fa-angle-right ml-2"></i></b-button>
                </div>
              </div>
            </b-card-footer>
          </template>
        </form-wizard>
      </b-form>
  </b-card>
</template>
<script>
  import {FormWizard, TabContent, } from 'vue-form-wizard'
  import BadgeCheck from '@/components/BadgeCheck.vue'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import _ from 'lodash'
  export default {
    components: {
      FormWizard, TabContent, BadgeCheck
    },
    props: {
      row: Object,
      dropdown: Object,
      mrCashless: Array
    },
    data(){
      return {
        routeFieldsUpdate: [
          { key: 'num', label: '#' },
          { key: 'pic', label: 'Destination PIC' },
          'destination',
          { key: 'distance', label: 'Est. Distance' },
          'purpose',
          'action'
        ],
        routeFields: [
          { key: 'num', label: '#' },
          { key: 'pic', label: 'Destination PIC' },
          'destination',
          { key: 'distance', label: 'Est. Distance' },
          'purpose',
        ],
        routeItems: [],
        fuelPercentage: [],
      }
    },
    methods: {
      deleteRoute(index){
        this.$swal({
          title: 'Are you sure to delete this route?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, sure',
          cancelButtonText: 'No, Cancel',
        }).then(result => {
          if(result.value){
            this.row.tbt_destination.splice(index, 1)
          }
        })
      },
      setUpdateRoute(data, value = true){
        this.$set(data, 'onEdit', value)
        if(value){
          this.$set(data, 'temp_data', {pic: data.pic, destination: data.destination, purpose: data.purpose})
        }else{
          data.pic = data.temp_data.pic
          data.destination = data.temp_data.destination
          data.purpose = data.temp_data.purpose
          this.$set(data, 'onEdit', false)
        }
      },
      setCurrentAmount(newVal){
        const current = this.mrCashless.find(v => v.value == newVal)
        if(current){
          this.$set(this.row, 'tt_ready_cashless', current.mct_current_amount)
        }
      },
      doReadyToStart(){
        this.$swal({
          title: "Loading...",
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => { this.$swal.showLoading() }
        })

        return this.$parent.doSubmit('/do/' + this.$parent.modulePage, Object.assign({type: 'add'}, {...this.row}), (type, resp) => {
          if(type == 'success'){
            this.$swal({
              title: resp.message,
              icon: 'success',
              confirmButtonText: 'Oke'
            }).then(() => {
                this.$router.push({name: 'BoTransportOnGoing'})
            })
          }else{
            if(resp.response.status==422) {
              var msg = ""
              global.$.each(Object.keys(resp.response.data),(i,v)=>{
                msg = msg+" <br/>"+_.values(resp.response.data[v])
                return global.Swal.fire("Terjadi Kesalahan Validasi!", msg)
              })
            }
            else if(resp.response.status==400){
              return global.Swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
            else {
              return global.Swal.fire({
                title: 'Terjadi suatu kesalahan, silakan coba lagi nanti.',
                text: 'Mohon periksa kembali data anda',
                icon: 'error',
              })
            }
          }
        }, 'POST', 'VFormTwo', this.$refs)
      },
      getOptionLabel(master, id){
        if(Array.isArray(master)){
          const opt = master.find(v => v.value == id)
          if(opt){
            return opt.label
          }else{
            return id
          }
        }else{
          return id
        }
      },
      updateDestination(value, index){
        this.$set(this.row.tbt_destination[index], 'distance', value)
      },
      validateStepOne(){
        return this.$refs.VFormOne.validate().then(success => {
          if(!success) return false
          return true
        })
      },
      validateStepTwo(){
        return this.$refs.VFormTwo.validate().then(success => {
          if(!success) return false
          return true
        })
      }
    },
    computed: {
      totalEstimateDistance() {
        if(this.row.tbt_destination){
          let totalDistance = this.row.tbt_destination.reduce((a, b) => (+a || 0) + (+b.distance || 0), 0)
          totalDistance = +(totalDistance.toFixed(2).toString())
          this.$set(this.row, 'tt_total_distance', totalDistance)
          return totalDistance
        }else{
          return 0
        }
      },  
      dropdownDefault(){
        return this.dropdown.default
      },
      dropdownPercentage(){
        return this.dropdown.percentage
      },
      dropdownWeight(){
        return this.dropdown.weight
      },
      mustOK(){
        return this.row.tt_use_deviasi == 'Y' ? 'required|is:ok' : ''
      },
      required(){
        return this.row.tt_use_deviasi == 'Y' ? 'required' : ''
      }
    }
  }
</script>
